import React from 'react';
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  isSameMonth,
  isSameDay,
  addDays,
  addMonths,
  subMonths,
  parseISO,
} from 'date-fns';

import styles from './SecondScreen.module.scss';
import calendarControl from '../../images/calendar-control.svg';
import listView from '../../images/list-view-icon.svg';
import listViewBlue from '../../images/list-view-icon-blue.svg';
import calendarView from '../../images/calendar-view-icon.svg';
import calendarViewBlue from '../../images/calendar-view-icon-blue.svg';
import closeButton from '../../images/Button-Exit-White-Inactive.svg';

export default class SecondScreen extends React.Component<any> {
  state = {
    currentMonth: new Date(),
    calendarView: true,
    activeDay: null,
    activeEventsList: [],
    allEventsList: this.props.events.edges[0].node.childEventsJson.eventsList,
  };

  renderHeader() {
    return (
      <div className={styles.header}>
        <div
          className={styles.calendarViewControl}
          style={{ opacity: this.state.calendarView ? 0.5 : 1 }}
          onClick={() => this.setState({ calendarView: false })}
        >
          <img src={listView} alt="list view"></img>
          <span>LIST VIEW</span>
        </div>
        <div className={styles.monthControls} data-year={format(this.state.currentMonth, 'yyyy')}>
          <img
            onClick={this.prevMonth}
            src={calendarControl}
            alt="month control"
            style={{ transform: 'rotate(90deg)' }}
          ></img>
          <div>
            <span>{format(this.state.currentMonth, 'MMMM')}</span>
          </div>
          <img
            onClick={this.nextMonth}
            src={calendarControl}
            alt="month control"
            style={{ transform: 'rotate(270deg)' }}
          ></img>
        </div>
        <div
          className={styles.calendarViewControl}
          style={{ opacity: !this.state.calendarView ? 0.5 : 1 }}
          onClick={() => this.setState({ calendarView: true })}
        >
          <img src={calendarView} alt="calendar view"></img>
          <span>CALENDAR VIEW</span>
        </div>
      </div>
    );
  }

  renderCells() {
    const monthStart = startOfMonth(this.state.currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const rows = [];
    let day = startDate as any;

    while (day <= endDate) {
      let days = [];

      for (let i = 0; i < 7; i++) {
        let events = this.state.allEventsList.filter(
          (event) => isSameDay(day, parseISO(event.date)) && isSameMonth(day, monthStart),
        );
        let today = day;

        days.push(
          <div
            key={today}
            className={`
              ${styles.day}
              ${isSameDay(day, new Date()) ? styles.today : ''}
              ${events.length ? styles.eventDay : ''}
            `}
            onClick={() =>
              events.length ? this.setState({ activeDay: today, activeEventsList: events }) : null
            }
          >
            {events.length ? (
              <div className={styles.mobileBg} style={{ background: events[0].background }}></div>
            ) : null}

            <div className={styles.desktopDate}>
              {isSameMonth(day, monthStart) ? format(day, 'dd EEEE') : ''}
            </div>
            <div className={styles.mobileDate}>
              {isSameMonth(day, monthStart) ? format(day, 'dd') : ''}
            </div>

            {events.length ? <div className={styles.seeMore}>see more</div> : null}

            {events.length ? <div className={styles.eventDayShadow}></div> : null}
            {events.length ? (
              <div className={styles.eventsBox} style={{ backgroundColor: events[0].background }}>
                <span className={styles.eventName}>
                  {events[0].title} @ <br></br>
                  {events[0].locationTitle}
                </span>
                {events.length > 1 ? (
                  <span className={styles.moreEvents}>+{events.length - 1} more</span>
                ) : null}
              </div>
            ) : null}
          </div>,
        );

        day = addDays(day, 1);
      }

      rows.push(
        <div className={styles.row} key={day}>
          {days}
        </div>,
      );
    }

    return <div className={styles.calendarBody}>{rows}</div>;
  }

  renderList() {
    const monthStart = startOfMonth(this.state.currentMonth);
    const monthEnd = endOfMonth(monthStart);

    const dates = [];
    let day = monthStart as any;

    while (day <= monthEnd) {
      if (this.state.allEventsList.find((event) => isSameDay(day, parseISO(event.date)))) {
        const eventsMarkup = [];
        const events = this.state.allEventsList.filter((event) =>
          isSameDay(day, parseISO(event.date)),
        );

        events.map((event, i) => {
          eventsMarkup.push(
            <div className={styles.eventBox} key={event.title + i}>
              <div>
                <span className={styles.name}>
                  <b style={{ fontWeight: 800 }}>{event.title}</b> at {event.locationTitle}
                </span>
                <span className={styles.address}>
                  {event.startTime.replace(' ', '')}-{event.endTime.replace(' ', '')} |{' '}
                  {event.locationAddress}
                </span>
                <span className={styles.info}>{event.description}</span>
              </div>
              <div className={styles.rsvp}>RSVP</div>
              {i !== events.length - 1 ? <div className={styles.bottomLine}></div> : null}
            </div>,
          );
        });

        dates.push(
          <div key={day}>
            <div className={styles.date}>{format(day, 'EEEE, MMMM do, yyyy')}</div>
            {eventsMarkup}
          </div>,
        );
      }
      day = addDays(day, 1);
    }

    return (
      <div className={styles.listBody}>
        <div className={styles.wrapper}>{dates}</div>
      </div>
    );
  }

  renderEventsListModal() {
    return (
      <div
        className={styles.backdrop}
        onClick={() => {
          this.setState({ activeDay: null });
        }}
      >
        <div
          className={styles.eventsListModal}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={styles.header}>
            <span>{format(this.state.activeDay, 'EEEE, MMMM do, yyyy')}</span>
            <img
              src={closeButton}
              alt="close button"
              onClick={() => this.setState({ activeDay: null })}
            ></img>
          </div>
          <div className={styles.eventsWrapper}>
            {this.state.activeEventsList.map((event, i) => (
              <div className={styles.eventBox} key={event.name + i}>
                <span className={styles.name}>
                  {event.title} at <br></br>
                  {event.locationTitle}
                </span>
                <span className={styles.address}>
                  {event.startTime.replace(' ', '')}-{event.endTime.replace(' ', '')} |{' '}
                  {event.locationAddress}
                </span>
                <span className={styles.info}>{event.description} </span>
                <div className={styles.rsvp} style={{ margin: '0 auto' }}>
                  RSVP
                </div>
                {i !== this.state.activeEventsList.length - 1 ? (
                  <div className={styles.bottomLine}></div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  nextMonth = () => {
    this.setState({
      currentMonth: addMonths(this.state.currentMonth, 1),
    });
  };

  prevMonth = () => {
    this.setState({
      currentMonth: subMonths(this.state.currentMonth, 1),
    });
  };

  render() {
    return (
      <section className={styles.screenBox}>
        <div className={styles.mobileCalendarViewControls}>
          <div
            className={styles.calendarViewControl}
            style={{ opacity: !this.state.calendarView ? 0.5 : 1 }}
            onClick={() => this.setState({ calendarView: true })}
          >
            <img src={calendarViewBlue} alt="calendar view"></img>
            <span>CALENDAR VIEW</span>
          </div>
          <div
            className={styles.calendarViewControl}
            style={{ opacity: this.state.calendarView ? 0.5 : 1 }}
            onClick={() => this.setState({ calendarView: false })}
          >
            <img src={listViewBlue} alt="list view"></img>
            <span>LIST VIEW</span>
          </div>
        </div>
        <div className={styles.calendar}>
          {this.renderHeader()}
          {this.state.calendarView ? this.renderCells() : this.renderList()}
        </div>
        {this.state.activeDay ? this.renderEventsListModal() : null}
      </section>
    );
  }
}
