import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from 'components/layouts/main/Layout';
import FirstScreen from 'components/events/FirstScreen';
import SecondScreen from 'components/events/SecondScreen';
import { graphql } from 'gatsby';

export default class EventsPage extends React.Component<any> {
  render() {
    const { data } = this.props;
    const upcomingEvents = data.allFile.edges[0].node.childEventsJson.eventsList
      .sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf())
      .reverse()
      .slice(0, 3);

    return (
      <Layout location={this.props.path}>
        <Helmet>
          <title>Events</title>
        </Helmet>
        <FirstScreen events={upcomingEvents}></FirstScreen>
        <SecondScreen events={data.allFile}></SecondScreen>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFile(
      filter: { name: { eq: "events" } }
      sort: { order: ASC, fields: childEventsJson___eventsList___date }
    ) {
      edges {
        node {
          childEventsJson {
            eventsList {
              title
              date
              startTime
              endTime
              description
              locationTitle
              locationAddress
              background
            }
          }
        }
      }
    }
  }
`;
