import React from 'react';
import { format } from 'date-fns';

import styles from './FirstScreen.module.scss';

interface Props {
  events: any[];
}

const FirstScreen: React.FC<Props> = ({ events }) => (
  <section className={styles.screenBox}>
    <div className={styles.grayTriangle}></div>
    <div className={styles.textWrapper}>
      <h2>
        keep <br className={styles.desktopBreak}></br>up <br className={styles.mobileBreak}></br>{' '}
        with us
      </h2>
      <p className={styles.postExcerpt}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua.
      </p>
    </div>
    <div className={styles.whiteLine}></div>
    <div className={styles.whiteLine + ' ' + styles.whiteLineBottom}></div>
    <div className={styles.upcomingEvents}>
      <div className={styles.heading}>
        <span>upcoming events</span>
      </div>
      {events.map((event, i) => (
        <div key={i}>
          <span className={styles.date}>{format(new Date(event.date), 'dd.MM.yyyy')}</span>
          <span>{event.title}</span>
        </div>
      ))}
      <div className={styles.rsvp}>RSVP</div>
    </div>
  </section>
);

export default FirstScreen;
